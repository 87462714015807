import React from "react";
import { Box, Typography } from "@mui/material";
import { Trans } from "@lingui/macro";
import { useLocalization } from "gatsby-theme-i18n";
import NewLayout from "../../components/NewLayout";
import Container from "../../components/Container";
import executiveCommittees from "../../cms/executiveCommittees/executiveCommittees";
import salesStructures from "../../cms/salesStructures/salesStructures";
import boardOfDirectors from "../../cms/boardOfDirectors/boardOfDirectors";
import InterestedItems from "../../components/InterestedItems";
import ToggleButtonGroupCustomized from "../../components/ToggleButtonGroupCustomized";
import DetailsGridTeam from "../../components/DetailsGridTeam";

const buttons = () => [
  {
    value: "executive_committees",
    label: <Trans>Executive Committee</Trans>,
  },
  {
    value: "directors",
    label: <Trans>Board of Directors</Trans>,
  },
  // {
  //   value: "sales",
  //   label: <Trans>Sales Structure</Trans>,
  // },
];

function OurTeamPage() {
  const { locale } = useLocalization();
  const [currentTab, setCurrentTab] = React.useState(buttons()[0].value);
  const content = {
    directors: boardOfDirectors[locale].filter(
      ({
        attributes: {
          companies: { data },
        },
      }) => !data.map(({ attributes }) => attributes.name).includes("ExCo")
        && !data.map(({ attributes }) => attributes.name).includes("External"),
    ),
    executive_committees: executiveCommittees[locale],
    sales: salesStructures[locale],
  };

  return (
    <NewLayout byDefault apps="about.about_team">
      <Container pb={8}>
        <Typography mt={3} variant="h2">
          <Trans>Our Team</Trans>
        </Typography>
        <Box mt={{ xs: 3, sm: 7 }}>
          <ToggleButtonGroupCustomized
            items={buttons()}
            tab={currentTab}
            onChangeTab={(value) => setCurrentTab(value)}
          />
        </Box>
        <Box mt={{ xs: 2, sm: 5 }}>
          <DetailsGridTeam items={content[currentTab]} />
        </Box>
        <InterestedItems isSmall />
      </Container>
    </NewLayout>
  );
}

export default OurTeamPage;
